import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/seo";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16rem;
  @media (max-width: 768px) {
    gap: 8rem;
  }
`;

const StyledSection = styled.section`
  display: flex;
  flex-direction: row;
  margin-left: 10vw;
  margin-right: 10vw;
  padding-bottom: 3rem;
  p {
    color: var(--grey);
    width: 70%;
    line-height: 1.5;
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    margin-left: 5vw;
    margin-right: 5vw;
    p {
      width: 90%;
    }
  }
`;

const IntroSection = styled.section`
  display: flex;
  margin-left: 10vw;
  margin-right: 10vw;
  padding-bottom: 3rem;
  gap: 30%;
  h2 {
    color: var(--grey);
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  h3 {
    color: var(--white);
    width: 70%;
  }
  p {
    color: var(--grey);
    width: 70%;
    line-height: 1.5;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    margin-left: 5vw;
    margin-right: 5vw;
    h2 {
      font-size: 2rem;
    }
    p {
      width: 90%;
    }
  }
`;

const StyledLandingContainer = styled.div`
  padding-top: 15rem;
  @media (max-width: 768px) {
    padding-top: 6rem;
  }
`;

const StyledActionContainer = styled.div`
  margin-top: 5rem;
`;

const ButtonContainer = styled(motion.div)`
  border-radius: 5rem;
  width: 20rem;
  padding: 3px;
  border: 2px solid transparent;
`;

const StyledButton = styled(motion.button)`
  background: var(--white);
  color: black;
  border-radius: 4rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 1.5rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  width: 20rem;
`;

const StyledLearnMore = styled(motion.a)`
  margin-top: 5rem;
  display: flex;
  gap: 2rem;
  color: var(--white);
  text-decoration: none;
  align-items: center;
`;

const StyledCircleButton = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
`;

const IllustrationContainer = styled.div`
  width: 120%;
  margin-right: -8rem;
  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
  div {
    background-color: transparent !important;
  }
`;

const iconMotion = {
  rest: {
    border: "1px solid var(--grey)",
  },
  hover: {
    border: "1px solid var(--white)",
  },
};

const IndexPage = () => (
  <StyledContainer>
    <SEO title="Home" />
    <StyledSection>
      <StyledLandingContainer>
        <h2>Hi, I'm Shehbaz Jafri</h2>
        <p>
          I'm a software engineer based in India. I'm passionate about building
          products that solve real-world problems.
        </p>
        <StyledActionContainer>
          <ButtonContainer
            whileHover={{
              padding: "3px",
              border: "2px solid var(--white)",
              transition: {
                duration: 0.5,
              },
            }}
          >
            <StyledButton>Read the blog</StyledButton>
          </ButtonContainer>
        </StyledActionContainer>
        <StyledLearnMore
          initial="rest"
          whileHover="hover"
          animate="rest"
          onClick={() => {
            // smooth scroll to intro section
            window.scrollTo({
              top: document.querySelector("#about").offsetTop,
              behavior: "smooth",
            });
          }}
        >
          <StyledCircleButton variants={iconMotion}>
            <FontAwesomeIcon icon={faArrowDown} />
          </StyledCircleButton>
          <span>Learn more</span>
        </StyledLearnMore>
      </StyledLandingContainer>
      <IllustrationContainer>
        <StaticImage
          src="../images/web-dev.png"
          alt="A web developer sitting at his desk with a laptop and two monitors"
          layout="fullWidth"
          height="100%"
          aspectRatio={1.2 / 1}
        />
      </IllustrationContainer>
    </StyledSection>
    <IntroSection id="about">
      <h2>Intro</h2>
      <div>
        <h3>Hi, I'm Shehbaz Jafri. I build web apps and mobile apps.</h3>
        <p>
          I use HTMl, CSS, and JavaScript. Though I keep learning and keep
          trying out new things, my focus right now is React.js, Gatsby, and
          Next.js. When I’m not hanging out with family/friends or working, you
          can find me reading a good book or playing video games.
        </p>
        <StyledLearnMore initial="rest" whileHover="hover" animate="rest">
          <span>Learn a little more...</span>
          <StyledCircleButton variants={iconMotion}>
            <FontAwesomeIcon icon={faArrowRight} />
          </StyledCircleButton>
        </StyledLearnMore>
      </div>
    </IntroSection>
  </StyledContainer>
);

export default IndexPage;
